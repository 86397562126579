<template>
  <div v-if="isLoading" id="preloader">
    <div class="spinner-border color-highlight" role="status"></div>
  </div>

  <div id="page">
    <!-- Header -->
    <Header :shareBtn="true" />
    <!--End Header -->

    <!-- Footer -->
    <Footer logged />
    <!--End Footer -->

    <div class="page-title-clear"></div>
    <div class="page-content" v-if="!isLoading">
      <!--Job Listing -->
      <div v-if="listJob">
        <ListJob v-for="job in listJob" :key="job.job" :job="job.job" />
      </div>

      <div v-if="listJob.length === 0" class="mb-5">
        <EmptyState
          :message1="'Maaf saat ini Belum ada lowongan yang disimpan'"
          :cardCenter="false"
        />
      </div>
    </div>
    <div @click="menuClose" class="menu-hider"></div>
  </div>
</template>

<script>
import axios from "axios";
import { init_template, menuClose, menuOpen } from "@/components/menu/index.js";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

import EmptyState from "@/components/EmptyState";

import ListJob from "@/components/list_job/ListJob";
export default {
  name: "Bookmark",
  props: {
    job: Object,
  },
  inject: ["getApply"],
  components: {
    Header,
    Footer,
    ListJob,
    EmptyState,
  },
  data() {
    return {
      dataSearch: "",
      resetFilter: false,
      dataFilterList: {},
      listJob: [],
      apiUrl: "/api/v1/job-seeker/bookmark",
      isAuthenticated: this.$store.state.isAuthenticated,
      isLoading: true,
      next: "",
      reqData: false,
      isFilter: false,
      companyDetail: "",
    };
  },
  beforeMount() {},
  mounted() {
    document.title = "pasjob";
    init_template();
    this.getData();
  },
  methods: {
    menuOpen,
    menuClose,
    async getJobList() {
      var url = "/api/v1/job-seeker/bookmark";
      if (url) {
        await axios
          .get(url)
          .then((response) => {
            this.listJob = response.data;
            console.log(response.data);
            this.isLoading = false;
          })
          .catch((error) => {
            this.reqData = false;
            console.log(error);
          });
      }
    },

    async getData() {
      this.getJobList();
    },
  },
};
</script>

<style>
.keep-spaces {
  white-space: pre-wrap;
}
.bluer-layer {
  position: relative;
  filter: blur(4px);
}
#text-information {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(208, 208, 208, 0.34);
  /*! filter: blur(5px); */
  padding: 20px;
  color: black;
  border-radius: 20px;
}
</style>
